@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");

:root {
  width: inherit;
}

html,
body,
#root {
  height: 100%;
  background-color: #f5f5f5;
}

html {
  font-size: 10px;
}

@font-face {
  font-family: "New York Extra Large";
  font-style: normal;
  src: url("./assets/fonts/NewYorkExtraLargeBold.woff") format("woff");
}

.MuiTypography-root {
  font-family: "Montserrat", sans-serif;
}
