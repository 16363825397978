.slider-container {
  display: flex;
  align-items: center;
}

.slider-dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #d91f05;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider-dot.active {
  background-color: #d91f05;
}
